@import "../../global.scss";

.contact{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @include mobile {
        flex-direction: column;
      }

    
        .left{
            height: 25%;            
            overflow: hidden;
            margin-top: 10px;
            
            img{
                height: 100%;
                position: relative;
                left: 43%;
                @include mobile {
                    left: 20%;
                  }

            }

        }

        .right{
            
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2{
                font-size: 30px;
            }

            form{
                width: 70%;
                height: 70%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;

                input{
                    width: 300px;
                    height: 30px;
                    font-size: 14px;

                    @include mobile {
                        width: 200px;
                        height: 20px;
                      }
                }
                textarea{
                    width: 300px;
                    height: 200px;
                    font-size: 14px;

                    @include mobile {
                        width: 200px;
                        height: 90px;
                      }

                }
                button{
                    width: 150px;
                    height: 30px;
                    color: white;
                    background-color: rgb(141, 75, 51);
                    border: none;
                    border-radius: 10px;
                    font-weight: 500;
                    cursor: pointer;
                }
                span{
                    color: green;

                }
                
            }
        }
       
       
    
}