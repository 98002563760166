@import "../../global.scss";

.portfolio{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    h1{
        font-style: 50px;

        @include mobile{
            font-size: 20px;
        }
    }

    ul{
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;

        @include mobile{
            margin: 10px 0;
            flex-wrap: wrap;
            justify-content: center;
        }

    }
    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;//move to next row when there is no space

        @include mobile{
            width: 100%;
        }

        .item{
            margin: 10px 20px; //top-botto:10px left-right:20px
            width: 220px;
            height: 150px;
            border-radius: 20px;
            border: 1px solid rgb(205, 222, 228);
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition: all .5s ease;//of animation part(the hover part)

            @include mobile{
                width: 130px;
                height: 100px;
            
            }

            h3{
                position: absolute;
                font-style: 20px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;//fill out the container
                z-index: 1;
            }
            &:hover{
                background-color: $maincolor;

                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }

    
}