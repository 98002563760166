@import "../../global.scss";
.works{
    background-color: crimson;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .arrow{
        font-size: 50px;
        position: absolute;
        color: white;
        cursor: pointer;

        @include mobile{
            bottom: 10px;
        }

        &.left{
            left: 100px;
        }

        &.right{
            right: 100px;
        }
    }

    .slider{
        height: 350px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;

        @include mobile{
            height: 100vh;
            // flex-direction: column;
            justify-content: center;
            
        }

        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;

            .item{
                width: 700px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile{
                    width: 90%;
                    height: 50%;
                    
                }

                .left{
                    flex: 4;
                    height: 80%;       
                    display: flex;
                    align-items: center;
                    justify-content: center;      
                    
                    .leftContainer{
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .imgContainer{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: rgb(218, 146, 119);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img{
                                width: 15px;
                            }
                        }
                        h2{
                            font-style: 20px;
                        }
                        p{
                            font-style: 13px;
                        }
                        span{
                            font-style: 12px;
                            font-weight: 600;
                            text-decoration: underline;
                        }


                    }
                }
                .right{
                    flex: 8;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden; 

                    img{
                        width: 400px;
                        transform: rotate(-10deg);
                    }
                }
            }
        }
    }
}