@import "../../global.scss";

.portfolioList{
    font-size: 18px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;
    color: black;
    // @include mobile{
    //  margin-right: 20px;       
    // }

    &.active{
        background-color:$maincolor ;
        color: white;
    }
}